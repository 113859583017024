import React, {useState} from 'react';
import avatarKH from '../../assets/img/avatars/14.jpg';
import {Servicer} from '../../models/types';

type ServicersProps = {
  servicers: Servicer[]
  setCheckedServicers: Function
  setServicer: Function
}

const ServicerAside = (props: ServicersProps) => {

  const { servicers, setCheckedServicers, setServicer } = props

  const [checkedValues, setCheckedValues] = useState<Servicer[]>([]);

  const handleChange = (e: any) => {
    const {checked, value} = e.target;
    console.log('---');
    console.log(checked);
    console.log(value);
    if (checked) {
      const data = [...checkedValues, servicers.filter((item) => item.code === value)[0]]
      setCheckedValues(data);
      setCheckedServicers(data);
    } else {
      const data = checkedValues.filter((item) => item.code !== value)
      setCheckedValues(data);
      setCheckedServicers(data);
    }
  };

  const SelectEmpty = () => {
    setCheckedValues([]);
    setCheckedServicers([]);
  }

  const SelectAll = () => {
    setCheckedValues(servicers);
    setCheckedServicers(servicers);
  }

  return (
    <div className="tab-pane fade h-100" id="tab-customer" role="tabpanel">
      <div className="d-flex flex-column h-100">
        <div className="hide-scrollbar">
          <div className="container py-8">

            {/* Title */}
            <div className="mb-8">
              <h2 className="fw-bold m-0">客服列表</h2>
            </div>

            {/* Search */}
            <div className="mb-6">
              <form action="#">
                <div className="input-group">
                  <div className="input-group-text">
                    <div className="icon icon-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                           className="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </div>
                  </div>

                  <input type="text" className="form-control form-control-lg ps-0" placeholder="搜索客服"
                         aria-label="搜索客服..."/>
                </div>
              </form>
            </div>

            {/* List */}
            <div className="card-list">
              <div className="card border-0 mb-5">
                <div className="card-footer">
                  <div className="row gx-5">
                    <div className="col">
                      <a href="#" className="btn btn-sm btn-secondary w-100" onClick={SelectEmpty}>全部取消</a>
                    </div>
                    <div className="col">
                      <a href="#" className="btn btn-sm btn-primary w-100" onClick={SelectAll}>全部选中</a>
                    </div>
                  </div>
                </div>
              </div>
              {servicers?.map((item, index) => (
                <div key={index}>
                  {/* Group */}
                  <div className="my-5">
                    <small className="text-uppercase text-muted"></small>
                  </div>

                  {/* Card */}
                  <div className="card border-0 mt-5">
                    <div className="card-body">

                      <div className="row align-items-center gx-5">
                        <div className="col-auto">
                          <div className="avatar ">
                            {/*<img className="avatar-img" src={item.avatar} alt=""/>*/}
                            <a href="#"
                               data-bs-toggle="modal"
                               data-bs-target="#modal-servicer"
                               className="avatar avatar-responsive"
                               onClick={() => setServicer(item)}
                            >
                              {
                                (item.avatar === "")
                                  ?
                                  <span className="avatar-text">客服</span>
                                  :
                                  <img className="avatar-img" src={item.avatar} alt={item.name}/>
                              }
                            </a>
                          </div>
                        </div>
                        <div className="col">
                          <h5>{item.name}</h5>
                          <p></p>
                        </div>
                        <div className="col-auto">
                          <div className="form-check">
                            <input className="form-check-input"
                                   style={{cursor: 'pointer'}}
                                   type="checkbox"
                                   value={item.code}
                                   name="servicer_code"
                                   id={item.code}
                                   onChange={handleChange}
                                   checked={checkedValues.filter((servicer) => servicer.code === item.code).length > 0}
                            />
                            <label className="form-check-label" htmlFor={item.code}></label>
                          </div>
                        </div>
                      </div>
                      {/*<label className="stretched-label" htmlFor={item.code}></label>*/}
                    </div>
                  </div>
                </div>
              ))}

            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicerAside;
