import { http } from "../apis/request";
import {BusinessCard, Event, Link, Location, Media, Message, MiniProgram, MsgMenu, Text} from '../models/types';

interface ReqGetMessage {
    customer_code: string;
    servicer_code: string;
    offset: number;
    limit: number;
}
interface ResGetMessage {
    data: Message[];
    count: number;
}

interface ReqAddMessage {
    customer_code: string;
    servicer_code: string;
    msg_type: string;
    event?: Event;
    text?: Text;
    image?: Media;
    voice?: Media;
    video?: Media;
    file?: Media;
    location?: Location;
    link?: Link;
    business_card?: BusinessCard;
    miniprogram?: MiniProgram;
    msgmenu?: MsgMenu;
}
interface ResAddMessage {
}

class MessageService {
    get_msg_list(params: ReqGetMessage) {
        return http.get<ResGetMessage>(`/api/message`, {params});
    }
    add_msg_info(data: ReqAddMessage) {
        return http.post<ResAddMessage>(`/api/message`, data);
    }
}

export default new MessageService();
