import React, {useEffect, useState} from 'react';
import {Customer, UserHome} from "../../models/types";

type UserHomeProps = {
  userHome?: UserHome;
}

const SettingAside = (props: UserHomeProps) => {

  const { userHome } = props
  const [switchAudio, setSwitchAudio] = useState<string>('off');

  const handleChange = (e: any) => {
    // console.log(e.target.value);
    // console.log(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('switch_audio', 'on');
      setSwitchAudio('on');
    } else {
      localStorage.setItem('switch_audio', 'off');
      setSwitchAudio('off');
    }
  };

  useEffect(() => {
    const switchVal = localStorage.getItem('switch_audio') || 'off';
    setSwitchAudio(switchVal);
  }, []);

  return (
    <div className="tab-pane fade h-100" id="tab-setting" role="tabpanel">
      <div className="d-flex flex-column h-100">
        <div className="hide-scrollbar">
          <div className="container py-8">

            <div className="mb-8">
              <h2 className="fw-bold m-0">系统设置</h2>
            </div>

            {/* 账号设置 */}
            <div className="mt-8">
              <div className="d-flex align-items-center mb-4 px-6">
                <small className="text-muted me-auto">账号设置</small>
              </div>

              <div className="card border-0">
                <div className="card-body py-2">

                  <div className="accordion accordion-flush" id="accordion-profile">
                    <div className="accordion-item">
                      <div className="accordion-header" id="accordion-profile-1">
                        <a href="#" className="accordion-button text-reset collapsed" data-bs-toggle="collapse"
                           data-bs-target="#accordion-profile-body-1" aria-expanded="false"
                           aria-controls="accordion-profile-body-1">
                          <div>
                            <h5>资料设置</h5>
                            <p>更新个人资料信息</p>
                          </div>
                        </a>
                      </div>

                      <div id="accordion-profile-body-1" className="accordion-collapse collapse"
                           aria-labelledby="accordion-profile-1" data-parent="#accordion-profile">
                        <div className="accordion-body">
                          <div className="form-floating mb-6">
                            <input type="text" className="form-control" id="profile-name" placeholder="名称" value={userHome?.name} readOnly={true}/>
                            <label htmlFor="profile-name">名称</label>
                          </div>

                          <div className="form-floating mb-6">
                            <input type="email" className="form-control" id="profile-email" placeholder="邮箱" value={userHome?.email} readOnly={true}/>
                            <label htmlFor="profile-email">邮箱</label>
                          </div>

                          <div className="form-floating mb-6">
                            <input type="text" className="form-control" id="profile-phone" placeholder="手机" value={userHome?.mobile} readOnly={true}/>
                            <label htmlFor="profile-phone">手机</label>
                          </div>

                          <button type="button" className="btn btn-block btn-lg btn-primary w-100">保存</button>
                        </div>
                      </div>
                    </div>

                    {/*<div className="accordion-item">*/}
                    {/*  <div className="accordion-header" id="accordion-profile-2">*/}
                    {/*    <a href="#" className="accordion-button text-reset collapsed" data-bs-toggle="collapse"*/}
                    {/*       data-bs-target="#accordion-profile-body-2" aria-expanded="false"*/}
                    {/*       aria-controls="accordion-profile-body-2">*/}
                    {/*      <div>*/}
                    {/*        <h5>社交账号</h5>*/}
                    {/*        <p>设置外部社交账号</p>*/}
                    {/*      </div>*/}
                    {/*    </a>*/}
                    {/*  </div>*/}

                    {/*  <div id="accordion-profile-body-2" className="accordion-collapse collapse"*/}
                    {/*       aria-labelledby="accordion-profile-2" data-parent="#accordion-profile">*/}
                    {/*    <div className="accordion-body">*/}
                    {/*      <div className="form-floating mb-6">*/}
                    {/*        <input type="text" className="form-control" id="profile-twitter" placeholder="微信"/>*/}
                    {/*        <label htmlFor="profile-twitter">微信</label>*/}
                    {/*      </div>*/}

                    {/*      <div className="form-floating mb-6">*/}
                    {/*        <input type="text" className="form-control" id="profile-facebook" placeholder="微博"/>*/}
                    {/*        <label htmlFor="profile-facebook">微博</label>*/}
                    {/*      </div>*/}

                    {/*      <div className="form-floating mb-6">*/}
                    {/*        <input type="text" className="form-control" id="profile-instagram" placeholder="抖音"/>*/}
                    {/*        <label htmlFor="profile-instagram">抖音</label>*/}
                    {/*      </div>*/}

                    {/*      <button type="button" className="btn btn-block btn-lg btn-primary w-100">保存</button>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                  </div>

                </div>
              </div>
            </div>

            {/* 安全设置 */}
            <div className="mt-8">
              <div className="d-flex align-items-center my-4 px-6">
                <small className="text-muted me-auto">安全设置</small>
              </div>

              <div className="card border-0">
                <div className="card-body py-2">

                  <div className="accordion accordion-flush" id="accordion-security">
                    <div className="accordion-item">
                      <div className="accordion-header" id="accordion-security-1">
                        <a href="#" className="accordion-button text-reset collapsed" data-bs-toggle="collapse"
                           data-bs-target="#accordion-security-body-1" aria-expanded="false"
                           aria-controls="accordion-security-body-1">
                          <div>
                            <h5>密码设置</h5>
                            <p>修改登录密码</p>
                          </div>
                        </a>
                      </div>

                      <div id="accordion-security-body-1" className="accordion-collapse collapse"
                           aria-labelledby="accordion-security-1" data-parent="#accordion-security">
                        <div className="accordion-body">
                          <form action="#" autoComplete="on">
                            <div className="form-floating mb-6">
                              <input type="password" className="form-control" id="profile-current-password"
                                     placeholder="当前密码"/>
                              <label htmlFor="profile-current-password">当前密码</label>
                            </div>

                            <div className="form-floating mb-6">
                              <input type="password" className="form-control" id="profile-new-password"
                                     placeholder="新设密码"/>
                              <label htmlFor="profile-new-password">新设密码</label>
                            </div>

                            <div className="form-floating mb-6">
                              <input type="password" className="form-control" id="profile-verify-password"
                                     placeholder="确认密码"/>
                              <label htmlFor="profile-verify-password">确认密码</label>
                            </div>
                          </form>
                          <button type="button" className="btn btn-block btn-lg btn-primary w-100">保存</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* 系统通知 */}
            <div className="mt-8">
              <div className="d-flex align-items-center my-4 px-6">
                <small className="text-muted me-auto">系统通知</small>
              </div>

              <div className="card border-0">
                <div className="card-body py-2">
                  <div className="accordion accordion-flush" id="accordion-notifications">

                    <div className="accordion-item">
                      <div className="accordion-header">
                        <div className="row align-items-center">
                          <div className="col">
                            <h5>声音提示</h5>
                            <p>启用声音提示</p>
                          </div>
                          <div className="col-auto">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="accordion-notifications-check-3"
                                name="switch_audio"
                                onChange={handleChange}
                                checked={switchAudio === 'on'}
                              />
                              <label className="form-check-label" htmlFor="accordion-notifications-check-3"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* 登录设备 */}
            {/*<div className="mt-8">*/}
            {/*  <div className="d-flex align-items-center my-4 px-6">*/}
            {/*    <small className="text-muted me-auto">登录设备</small>*/}

            {/*    <div className="flex align-items-center text-muted">*/}
            {/*      <a href="#" className="text-muted small">清除设备</a>*/}

            {/*      <div className="icon icon-xs">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
            {/*             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"*/}
            {/*             className="feather feather-log-out">*/}
            {/*          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>*/}
            {/*          <polyline points="16 17 21 12 16 7"></polyline>*/}
            {/*          <line x1="21" y1="12" x2="9" y2="12"></line>*/}
            {/*        </svg>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  <div className="card border-0">*/}
            {/*    <div className="card-body py-3">*/}

            {/*      <ul className="list-group list-group-flush">*/}
            {/*        <li className="list-group-item">*/}
            {/*          <div className="row align-items-center">*/}
            {/*            <div className="col">*/}
            {/*              <h5>Windows ⋅ 中国, 上海</h5>*/}
            {/*              <p>今天 2:48 pm ⋅ 浏览器: Chrome</p>*/}
            {/*            </div>*/}
            {/*            <div className="col-auto">*/}
            {/*              <span className="text-primary extra-small">active</span>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}

            {/*        <li className="list-group-item">*/}
            {/*          <div className="row align-items-center">*/}
            {/*            <div className="col">*/}
            {/*              <h5>iPhone ⋅ 中国, 上海</h5>*/}
            {/*              <p>昨天 2:48 pm ⋅ 浏览器: Chrome</p>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </li>*/}
            {/*      </ul>*/}

            {/*    </div>*/}
            {/*  </div>*/}

            {/*</div>*/}

          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingAside;
