import { http } from "../apis/request";
import {Knowledge, Servicer} from '../models/types';

interface ReqServicerList {
    offset: number;
    limit: number;
}

interface ReqServicerLink {
    code: string;
}

interface ResServicerList {
    data: Servicer[];
    count: number;
}

interface ResServicerLink {
    url: string;
}

class ServicerService {
    get_list(params: ReqServicerList) {
        return http.get<ResServicerList>(`/api/servicer`, {params});
    }

    get_link(params: ReqServicerLink) {
        return http.get<ResServicerLink>(`/api/servicer/link`, {params});
    }
}

export default new ServicerService();
