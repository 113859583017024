import React from 'react';
import {UserHome} from "../models/types";

type NavigationProps = {
  userHome: UserHome
}

const Navigation = (props: NavigationProps) => {

  const { userHome } = props

  return (
    <nav className="navigation d-flex flex-column text-center navbar navbar-light hide-scrollbar">

      {/* 品牌 */}
      <a href="#" title="Messenger" className="d-none d-xl-block mb-6">
        <svg version="1.1" width="46px" height="46px" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 46 46"
             enableBackground="new 0 0 46 46" xmlSpace="preserve">
          <polygon opacity="0.7" points="45,11 36,11 35.5,1 "/>
          <polygon points="35.5,1 25.4,14.1 39,21 "/>
          <polygon opacity="0.4" points="17,9.8 39,21 17,26 "/>
          <polygon opacity="0.7" points="2,12 17,26 17,9.8 "/>
          <polygon opacity="0.7" points="17,26 39,21 28,36 "/>
          <polygon points="28,36 4.5,44 17,26 "/>
          <polygon points="17,26 1,26 10.8,20.1 "/>
        </svg>
      </a>

      <ul
        className="d-flex nav navbar-nav flex-row flex-xl-column flex-grow-1 justify-content-between justify-content-xl-center align-items-center w-100 py-4 py-lg-2 px-lg-3"
        role="tablist"
      >

        <li className="nav-item d-none d-xl-block invisible flex-xl-grow-1">
          <a className="nav-link py-0 py-lg-8" href="#" title="">
            <div className="icon icon-xl">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                   className="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </a>
        </li>

        {/* 客户列表 */}
        <li className="nav-item">
          <a className="nav-link py-0 py-lg-8" id="tab-friends" href="#tab-customer"
             title="Friends"
             data-bs-toggle="tab" role="tab">
            <div className="icon icon-xl">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                   strokeLinejoin="round" className="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
            </div>
          </a>
        </li>

        {/* 消息列表 */}
        <li className="nav-item">
          <a className="nav-link active py-0 py-lg-8" id="tab-chats" href="#tab-room"
             title="消息记录"
             data-bs-toggle="tab" role="tab">
            {/*<div className="icon icon-xl icon-badged">*/}
            {/*  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"*/}
            {/*       fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"*/}
            {/*       strokeLinejoin="round" className="feather feather-message-square">*/}
            {/*    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>*/}
            {/*  </svg>*/}
            {/*  <div className="badge badge-circle bg-primary">*/}
            {/*    <span>4</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="icon icon-xl">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                   strokeLinejoin="round" className="feather feather-message-square">
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
            </div>
          </a>
        </li>

        {/* 系统设置 */}
        <li className="nav-item">
          <a className="nav-link py-0 py-lg-8" id="tab-settings" href="#tab-setting"
             title="Settings" data-bs-toggle="tab" role="tab">
            <div className="icon icon-xl">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                   fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                   strokeLinejoin="round" className="feather feather-settings">
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
              </svg>
            </div>
          </a>
        </li>

        <li className="nav-item d-none d-xl-block">
          <a href="#" className="nav-link p-0 mt-lg-2" data-bs-toggle="modal" data-bs-target="#modal-profile-home">
            {
              (userHome.avatar === "")
              ?
              <div className="avatar avatar-online mx-auto">
                <span className="avatar-text">客服</span>
              </div>
              :
              <div className="avatar avatar-online mx-auto">
                <img className="avatar-img" src={userHome.avatar} alt={userHome.name}/>
              </div>
            }
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
