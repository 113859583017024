import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from "react-router-dom";
import 'zoom-vanilla.js';

const Image = () => {

  const location = useLocation();
  const name = new URLSearchParams(location.search).get('name') || '';

  return (
    <div className="container">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">
        <div className="col-12">
          <img className="img-fluid rounded" src={"https://zh-fy-chat.oss-rg-china-mainland.aliyuncs.com/media/" + name} data-action="zoom" alt=""/>
        </div>
      </div>
    </div>
  );
}


export default Image;
