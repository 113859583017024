import { http } from "../apis/request";

import { Knowledge } from '../models/types';

interface ReqKnowledgeList {
  content: string;
}
interface ResKnowledgeList {
}

class KnowledgeService {
  get_list(params: ReqKnowledgeList) {
    return http.get<Knowledge[]>(`/api/knowledge`, {params});
  }
}

export default new KnowledgeService();
