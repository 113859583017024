import React from 'react';
import {Route, unstable_HistoryRouter as Router, Routes, Outlet} from "react-router-dom";
import { BrowserHistory } from './utils/browser_history';

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CustomerInfo from "./pages/CustomerInfo";
import Image from "./pages/Image";

const App = () => {

  return (
    <Router history={BrowserHistory} >
      <Routes>
        <Route path="/" element={<Outlet/>}>
          <Route index element={<HomePage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/customer/info" element={<CustomerInfo/>}/>
          <Route path="/image" element={<Image/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
