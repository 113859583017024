import { http } from "../apis/request";

interface ReqAuthWechat {
    code: string;
}

interface ResAuthWechat {
    token: string;
}

class AuthService {
    auth_wechat(data: ReqAuthWechat) {
        return http.post<ResAuthWechat>(`/api/auth/wechat`, data);
    }
}

export default new AuthService();
