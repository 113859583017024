import { http } from "../apis/request";
import {Knowledge, Customer, Servicer} from '../models/types';

interface ReqCustomerGetList {
    code: string;
}

interface ResCustomerGetList {
    data: Customer[];
    count: number;
}

interface ReqCustomerSetInfo {
    code: string;
    real_name: string;
    mobile: string;
    email: string;
}

interface ResCustomerSetInfo {
}

interface ReqCustomerUpgradeService {
    open_kfid: string;
    external_userid: string;
}

interface ResCustomerUpgradeService {
}

class CustomerService {
    get_list(params: ReqCustomerGetList) {
        return http.get<ResCustomerGetList>(`/api/customer`, {params});
    }

    set_info(data: ReqCustomerSetInfo) {
        return http.put<ResCustomerSetInfo>(`/api/customer`, data);
    }

    upgrade_service(data: ReqCustomerUpgradeService) {
        return http.put<ResCustomerUpgradeService>(`/api/customer/service`, data);
    }
}

export default new CustomerService();
