import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import AuthService from '../services/auth';

const LoginPage = () => {

  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    if (code === undefined || code === null || code === "") {
      return
    }
    AuthService.auth_wechat({code: code}).then((e) => {
      setToken(e.data.token);
    });
  }, [code]);

  useEffect(() => {
    if (token === "") {
      return
    }
    localStorage.setItem('token', token);
    window.location.href = '/';
  }, [token]);

  return (
    <div className="container">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">

        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              <div className="row g-6">
                <div className="col-12">
                  <div className="text-center">
                    <h3 className="fw-bold mb-2">登录</h3>
                    <p>账号登录</p>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating">
                    <input type="email" className="form-control" id="signin-email"
                           placeholder="邮箱"/>
                    <label htmlFor="signin-email">邮箱</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating">
                    <input type="password" className="form-control" id="signin-password"
                           placeholder="密码"/>
                    <label htmlFor="signin-password">密码</label>
                  </div>
                </div>

                <div className="col-12">
                  <button className="btn btn-block btn-lg btn-primary w-100" type="submit">登录
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-8">
            <p>
              <a href={"https://login.work.weixin.qq.com/wwlogin/sso/login?state=STATE&login_type=CorpApp&agentid=" + process.env.REACT_APP_AGENTID + "&appid=" + process.env.REACT_APP_APPID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI}>企业微信登录</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default LoginPage;
