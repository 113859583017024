import { http } from "../apis/request";

interface ResMediaInfo {
  file_name: string;
  file_size: string;
}

class MediaService {
  get_media_info(id: string) {
    return http.get<ResMediaInfo>(`/api/media/${id}/info`, );
  }
}

export default new MediaService();
