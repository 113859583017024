import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment/moment';
import 'moment/locale/zh-cn';
import {ReactComponent as SendSVG} from '../../assets/img/icons/send.svg';
import {ReactComponent as PaperClipSVG} from '../../assets/img/icons/paperclip.svg';
import {ReactComponent as MoreHorizontalSVG} from '../../assets/img/icons/more-horizontal.svg';
import {ReactComponent as MoreVerticalSVG} from '../../assets/img/icons/more-vertical.svg';
import {ReactComponent as PlusSVG} from '../../assets/img/icons/plus.svg';
import {ReactComponent as ChevronLeftSVG} from '../../assets/img/icons/chevron-left.svg';
import avatarKF from '../../assets/img/avatars/1.jpg';
import {Room, Message} from '../../models/types';
import MessageService from '../../services/message';


moment.locale('zh-cn');

type ChatProps = {
  room?: Room
  messages: Message[]
  reply: string
  keyword: string
  msgID: string
  // customer_code: string
  // servicer_code: string
  nextPage: Function
  offset: number
  hasMore: boolean
}

const ChatMain = (props: ChatProps) => {

  const {room, messages, reply, keyword, msgID, nextPage, offset, hasMore} = props

  // const [scrollPosition,setScrollPosition] = useState();

  // const chatContainerRef = useRef<HTMLDivElement>(null);

  // const scrollRef = useRef(null);
  //
  // const handleScroll = (event: any) => {
  //   setScrollPosition(event.target.scrollTop);
  //   console.log(scrollPosition);
  // };

  const defaultFormData = {
    customer_code: '',
    servicer_code: '',
    // offset: 0,
    // limit: 10,
    msg_type: 'text',
    content: '',
  }

  const messagesEnd = useRef<HTMLDivElement>(null);

  const [formData, setFormData] = useState(defaultFormData);

  // const {offset, limit} = formData;
  const [show, setShow] = useState(true);

  const scrollToBottom = () => {
    if (messagesEnd && messagesEnd.current) {
      messagesEnd.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  useEffect(() => {
    if (offset > 0) {
      return
    }
    scrollToBottom();
  }, [messages]);

  // [].forEach.call(document.querySelectorAll('[data-horizontal-scroll]'), function (el: HTMLElement) {
  //   function scrollHorizontally(e: any) {
  //     e = window.event || e;
  //     const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
  //     el.scrollLeft -= (delta*28);
  //     e.preventDefault();
  //   }
  //
  //   if (el.addEventListener) {
  //     el.addEventListener('mousewheel', scrollHorizontally, false);
  //     el.addEventListener('DOMMouseScroll', scrollHorizontally, false);
  //   }
  // });

  useEffect(() => {
    console.log('reply', reply);
    setFormData({
      ...formData,
      ['content']: reply,
    });
  }, [reply]);

  // useEffect(() => {
  //   console.log('keyword', keyword);
  // }, [keyword]);

  // const [messages, setMessages] = useState<Message[]>([]);


  // React.useEffect(() => {
  //   // 滚动到底部
  //   if (!chatContainerRef.current) {
  //     return;
  //   }
  //   chatContainerRef.current.scrollIntoView({ behavior: 'smooth' });
  //   // chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; // 在height: 100vh的情况下无效
  // }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (room === undefined) {
      return
    }
    // if (formData.content === "") {
    //   return;
    // }

    const data = {
      customer_code: room.customer.code,
      servicer_code: room.servicer.code,
      msg_type: 'text',
      content: formData.content,
    }
    console.log(data);
    // getMessage(data);

    setFormData({
      ...formData,
      ['content']: '',
    });

    MessageService.add_msg_info(data).then((e) => {
      console.log('Success:', e.data);
      setFormData(defaultFormData);
    }).catch((error) => {
      console.error('Error:', error);
      alert(error.response.data.cause);
    });
  }

  const handleChange = (e: any) => {
    const {name, value} = e.target;
    const data = {
      ...formData,
      [name]: value,
    }
    setFormData(data);
    console.log(data);
  };

  const scrollTo = (msgId: string) => {
    if (msgId) {
      // 找到锚点
      let msgElement = document.getElementById(msgId);
      // 如果对应id的锚点存在，就跳转到锚点
      if (msgElement) { msgElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
    }
  }

  useEffect(() => {
    console.log('msgID', msgID);
    if (msgID !== "") {
      scrollTo(msgID);
    } else {
      scrollToBottom();
    }
  }, [msgID]);

  // const handleScroll = () => {
  //   const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
  //   console.log('scrollTop', scrollTop);
  //   console.log('clientHeight', clientHeight);
  //   console.log('scrollHeight', scrollHeight);
  //   if (scrollTop + clientHeight >= scrollHeight) {
  //     //
  //     console.log('scrollTop + clientHeight >= scrollHeight');
  //     // setPage( ( prevPage ) => prevPage + 1 );
  //
  //   }
  // };
  //
  // useEffect( () => {
  //   window.addEventListener ( 'scroll' , handleScroll);
  //   return  () =>  window.removeEventListener ( 'scroll' , handleScroll);
  // }, []);

  // if (show) {
  //   return (
  //     <Alert variant="danger" onClose={() => setShow(false)} dismissible>
  //       <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
  //       <p>
  //         Change this and that and try again. Duis mollis, est non commodo
  //         luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
  //         Cras mattis consectetur purus sit amet fermentum.
  //       </p>
  //     </Alert>
  //   );
  // }
  return (
    room === undefined ?
      <main className="main">
        <div className="container h-100">
          <div className="d-flex flex-column h-100 justify-content-center text-center">
            <div className="mb-6">
              <span className="icon icon-xl text-muted">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                     strokeLinejoin="round" className="feather feather-message-square">
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                </svg>
              </span>
            </div>
            <p className="text-muted">左侧菜单选择 <br/> 在此开始对话</p>
          </div>
        </div>
      </main>
      :
      <main className="main is-visible" data-dropzone-area="">
        <div className="container h-100">
          <div className="d-flex flex-column h-100 position-relative">

            <div className="chat-header border-bottom py-4 py-lg-7">
              <div className="row align-items-center">

                <div className="col-2 d-xl-none">
                  <a className="icon icon-lg text-muted" href="#" data-toggle-chat="">
                    <ChevronLeftSVG/>
                  </a>
                </div>

                {/*Content*/}
                <div className="col-8 col-xl-12">
                  <div className="row align-items-center text-center text-xl-start">
                    {/*Title*/}
                    <div className="col-12 col-xl-6">
                      <div className="row align-items-center gx-5">
                        <div className="col-auto">
                          <div className="avatar d-none d-xl-inline-block">
                            <a href="#" data-bs-toggle="modal"
                               data-bs-target={"#modal-profile-user"}
                               className="avatar avatar-responsive">
                              {
                                (room.customer.avatar === "")
                                ?
                                <span className="avatar-text">客户</span>
                                :
                                <img className="avatar-img" src={room.customer.avatar} alt={room.customer.real_name !== '' ? room.customer.real_name : room.customer.name}/>
                              }
                            </a>
                          </div>
                        </div>
                        {/*<div className="col-auto">*/}
                        {/*  <div className="avatar avatar-online">*/}
                        {/*    <span className="avatar-text">M</span>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        <div className="col overflow-hidden">
                          <h5 className="text-truncate">{room.customer.real_name !== '' ? room.customer.real_name : room.customer.name}</h5>
                          <p className="text-truncate">接待中
                            <span className='typing-dots'>
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/*Toolbar*/}
                    <div className="col-xl-6 d-none d-xl-block">
                      <div className="row align-items-center justify-content-end gx-6">
                        {/*<div className="col-auto">*/}
                        {/*  <a href="#" className="icon icon-lg text-muted" data-bs-toggle="offcanvas"*/}
                        {/*     data-bs-target="#offcanvas-more-group" aria-controls="offcanvas-more-group">*/}
                        {/*    <MoreHorizontalSVG/>*/}
                        {/*  </a>*/}
                        {/*</div>*/}

                        <div className="col-auto">
                          <div className="avatar-group">
                            <a href="#" className="avatar avatar-sm" data-bs-toggle="offcanvas"
                               data-bs-target="#offcanvas-data" aria-controls="offcanvas-data">
                              <span className="avatar-text" data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="<strong>快捷回复</strong><p>内容来源于知识库</p>">
                                <PlusSVG/>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-2 d-xl-none text-end">
                  <a href="#" className="icon icon-lg text-muted" data-offcanvas="info">
                    <MoreVerticalSVG/>
                  </a>
                </div>

              </div>
            </div>

            <div className="chat-body hide-scrollbar flex-1 h-100">

              {
                messages.length === 0
                  ?
                  <div className="chat-body-inner h-100" style={{paddingBottom: '87px'}}>
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="text-center mb-6">
                        <span className="icon icon-xl text-muted">
                          <SendSVG/>
                        </span>
                      </div>
                      <p className="text-center text-muted">暂无消息<br/>开始对话</p>
                    </div>
                  </div>
                  :
                  <div className="chat-body-inner" style={{paddingBottom: '87px'}}>

                    <div className="py-6 py-lg-10">
                      {
                        hasMore
                        &&
                        <div
                          className="message-divider"
                          style={{cursor: 'pointer', marginTop: 0}}
                          onClick={() => {
                            nextPage()
                          }}
                        >
                          <small className="text-muted">
                          <span className='typing-dots'>
                              <span>⇪</span>
                          </span>
                            <span className='typing-dots'>
                              <span>历史消息</span>
                          </span>
                            <span className='typing-dots'>
                              <span>⇪</span>
                          </span>
                          </small>
                        </div>
                      }
                      {messages.map((item, index) => (
                        <>
                          {/*Divider*/}
                          {
                            item.display_time !== undefined &&
                            <div
                              className="message-divider"
                              key={index}
                            >
                              <small
                                className="text-muted">{moment(item.display_time * 1000).format('YYYY-MM-DD')} {moment(item.display_time * 1000).format('dddd')}</small>
                            </div>
                          }

                          {/*Message*/}
                          <div
                            id={item.msgid.toString()}
                            className={item.servicer_userid !== undefined ? "message message-out" : "message"}
                            key={item.msgid.toString()}
                          >
                            <a href="#" data-bs-toggle="modal"
                               data-bs-target={item.servicer_userid !== undefined ? "#modal-profile-servicer" : "#modal-profile-user"}
                               className="avatar avatar-responsive">
                              {
                                (item.servicer_userid !== undefined)
                                  ?
                                  (room.servicer.avatar === "")
                                    ?
                                    <span className="avatar-text">客服</span>
                                    :
                                    <img className="avatar-img" src={room.servicer.avatar} alt={room.servicer.name}/>
                                  :
                                  (room.customer.avatar === "")
                                    ?
                                    <span className="avatar-text">客户</span>
                                    :
                                    <img className="avatar-img" src={room.customer.avatar} alt={room.customer.name}/>
                              }
                            </a>

                            <div className="message-inner">
                              <div className="message-body">
                                <div className="message-content">
                                  {item.msgtype === "text" &&
                                    <div className="message-text">
                                      {item.text?.content !== undefined && item.text?.content.indexOf('----') > 0
                                        ? (
                                          <>
                                            <blockquote className="blockquote overflow-hidden">
                                              <h6 className="text-reset text-truncate">{room.customer.name}</h6>
                                              <p className="small text-truncate">
                                                {item.text?.content.split('\n------\n')[0]}
                                              </p>
                                            </blockquote>
                                            <p>
                                              {item.text?.content.split('\n------\n')[1]}
                                            </p>
                                          </>
                                        )
                                        : (
                                          <p>{item.text?.content}</p>
                                        )
                                      }
                                    </div>
                                  }
                                  {item.msgtype === "image" &&
                                    <div className="message-gallery">
                                      <div className="row gx-3">
                                        <div className="col">
                                          <img className="img-fluid rounded"
                                               src={process.env.REACT_APP_BASE_URL + "api/media/" + item.image?.media_id}
                                               data-action="zoom" alt=""/>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {item.msgtype === "video" &&
                                    <div className="message-gallery">
                                      <div className="row gx-3">
                                        <div className="col">
                                          <video controls className="img-fluid rounded">
                                            <source
                                              src={process.env.REACT_APP_BASE_URL + "api/media/" + item.video?.media_id}
                                              type="video/webm"/>
                                          </video>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {item.msgtype === "link" &&
                                    <div className="message-gallery">
                                      <div className="row gx-3">
                                        <div className="col">
                                          {
                                            item.link?.desc.endsWith(".mp4")
                                              ?
                                              <video controls className="img-fluid rounded">
                                                <source
                                                  src={item.link?.url}
                                                  type="video/webm"/>
                                              </video>
                                              :
                                              <div className="row align-items-center gx-4">
                                                <div className="col-auto">
                                                  <a href={item.link?.url} className="avatar avatar-sm">
                                                    <div className="avatar-text bg-white text-primary">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                           viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                           strokeWidth="2" strokeLinecap="round"
                                                           strokeLinejoin="round" className="feather feather-link">
                                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                                        <polyline points="19 12 12 19 5 12"></polyline>
                                                      </svg>
                                                    </div>
                                                  </a>
                                                </div>
                                                <div className="col overflow-hidden">
                                                  <h6 className="text-truncate text-reset">
                                                    <a href={item.link?.url} className="text-reset">{item.link?.title}</a>
                                                  </h6>
                                                  <ul
                                                    className="list-inline text-uppercase extra-small opacity-75 mb-0">
                                                    <li className="list-inline-item">{item.link?.desc}</li>
                                                  </ul>
                                                </div>
                                              </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {item.msgtype === "file" &&
                                    <div className="message-text">
                                      <div className="row align-items-center gx-4">
                                        <div className="col overflow-hidden">
                                          <h6 className="text-truncate text-reset">
                                            <a
                                              href={process.env.REACT_APP_BASE_URL + "api/media/" + item.file?.media_id}
                                              className="text-reset">{item.file?.file_name}</a>
                                          </h6>
                                          <ul className="list-inline text-uppercase extra-small opacity-75 mb-0">
                                            <li className="list-inline-item">{item.file?.file_size}</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {item.msgtype === "msgmenu" &&
                                    <div className="message-text">
                                      <p>{item.msgmenu?.head_content}</p>
                                      <ul>
                                        {item.msgmenu?.list.map((menu, menu_index) => (
                                          <li key={menu.click?.id}><p>{menu.click?.content}</p></li>
                                        ))}
                                      </ul>
                                      <p>{item.msgmenu?.tail_content}</p>
                                    </div>
                                  }
                                </div>
                              </div>

                              <div className="message-footer">
                              <span
                                className="extra-small text-muted">{moment(item.send_time * 1000).format('YYYY-MM-DD HH:mm')}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div style={{clear: 'both', height: '1px', width: '100%'}} ref={messagesEnd}></div>
                    </div>
                  </div>
              }

            </div>

            <div className="chat-footer pb-3 pb-lg-7 position-absolute bottom-0 start-0">
              {/*Chat: Files*/}
              <div className="dz-preview bg-dark" id="dz-preview-row" data-horizontal-scroll="">
              </div>

              {/*Chat: Form*/}
              <form className="chat-form rounded-pill bg-dark" onSubmit={handleSubmit} id="chat-form">
                <div className="row align-items-center gx-0">
                  <div className="col-auto">
                    <a href="#" className="btn btn-icon btn-link text-body rounded-circle" id="dz-btn">
                      <PaperClipSVG/>
                    </a>
                  </div>

                  <div className="col">
                    <div className="input-group">
                      <textarea className="form-control px-0"
                                placeholder="回复内容"
                                rows={1}
                                data-autosize="true"
                                value={formData.content}
                                name="content"
                                onChange={handleChange}
                      ></textarea>

                    </div>
                  </div>

                  <div className="col-auto">
                    <button className="btn btn-icon btn-primary rounded-circle ms-5">
                      <SendSVG/>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </main>
  );
}

export default ChatMain;
