import axios from 'axios';
import { BrowserHistory as history } from '../utils/browser_history';


export const http = axios.create({
    // baseURL: 'http://127.0.0.1:8080/', // build之后的静态文件，打开之后依然能访问接口
    // baseURL: '/', // 生产模式
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-type': 'application/json'
    }
});

// 请求拦截
http.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token') || '';
        if (token !== '') {
            config.headers.set('Authorization', 'Bearer ' + token)
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截
http.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
      if (err.response && err.response.status === 401) {
        // Token 失效，跳转到登录页面
        history.push('/login');
      }
        return Promise.reject(err);
    }
);
