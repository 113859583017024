import React, {useEffect, useState} from 'react';

import {ReactComponent as CardHeaderSVG} from '../../assets/img/core/card-header.svg';
import {ReactComponent as GlobeSVG} from '../../assets/img/icons/globe.svg';
import {ReactComponent as MailSVG} from '../../assets/img/icons/mail.svg';
import {ReactComponent as PhoneCallSVG} from '../../assets/img/icons/phone-call.svg';
import {Servicer} from "../../models/types";
import { useQRCode } from 'next-qrcode';
// import {toPng} from 'html-to-image';
import * as htmlToImage from 'html-to-image';
import ServicerService from "../../services/servicer";

type ProfileServicerProps = {
  servicer?: Servicer
}

const ProfileServicerModal = (props: ProfileServicerProps) => {

  const {servicer} = props
  const [servicerLink, setServicerLink] = useState<string>('');

  const { Image: ImageQR } = useQRCode()

  const LogOut = () => {
    localStorage.setItem('token', '');
    window.location.href = '/';
  }

  const downloadQrImage = () => {
    const node = document.getElementById('servicer-qrcode');
    if (node === null || servicer === undefined) {
      return
    }
    htmlToImage.toBlob(node, { cacheBust: true })
      .then((blob) => {
        if (blob === null) {
          return
        }
        // 使用Blob生成下载链接
        const url = URL.createObjectURL(blob);

        // 创建一个链接并单击下载
        const link = document.createElement('a');
        link.href = url;
        link.download = servicer.name + '.png';
        link.click();
      }).catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (servicer === undefined) {
      return
    }

    const ReqServicerLink = {
      code: servicer.code
    };

    ServicerService.get_link(ReqServicerLink).then((e) => {
      console.log('Success:', e.data);
      setServicerLink(e.data.url);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }, [servicer]);

  return (
    <div className="modal fade" id="modal-servicer" tabIndex={-1} aria-labelledby="modal-servicer"
         aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content" id="servicer-qrcode">

          {/* Modal body */}
          <div className="modal-body py-0">
            {/* Header */}
            <div className="profile modal-gx-n">
              <div className="profile-img text-primary rounded-top-xl">
                <CardHeaderSVG/>

                <div className="position-absolute top-0 start-0 py-6 px-5">
                  <button type="button" className="btn-close btn-close-white btn-close-arrow opacity-100"
                          data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>

              <div className="profile-body">
                <div className="avatar avatar-xl align-items-center">
                  <img className="avatar-img" src={servicer?.avatar} alt={servicer?.name}/>
                </div>

                <h4 className="mb-1">{servicer?.name}</h4>
                {/*<p>last seen 5 minutes ago</p>*/}
              </div>
            </div>
            {/* Header */}

            <hr className="hr-bold modal-gx-n my-0"/>

            {/* List */}
            <ul className="list-group list-group-flush">
              {/*<li className="list-group-item">*/}
              {/*  <div className="row align-items-center gx-6">*/}
              {/*    <div className="col">*/}
              {/*      <h5>位置</h5>*/}
              {/*      <p>中国 - 上海</p>*/}
              {/*    </div>*/}

              {/*    <div className="col-auto">*/}
              {/*      <div className="btn btn-sm btn-icon btn-dark">*/}
              {/*        <GlobeSVG/>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</li>*/}

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  {
                    servicerLink === ''
                    ?
                    <></>
                    :
                    <ImageQR
                      text={servicerLink}
                      options={{
                        type: 'image/jpeg',
                        // quality: 1,
                        errorCorrectionLevel: 'M',
                        // margin: 3,
                        scale: 20,
                        // width: 200,
                        color: {
                          dark: '#2787f5ff',
                          // light: '#f7f9fbe5',
                          light: '#ffffffff',
                        },
                      }}
                    />
                  }
                  {/*<div className="col">*/}
                  {/*  <h5>邮箱</h5>*/}
                  {/*  <p>{}</p>*/}
                  {/*</div>*/}

                  {/*<div className="col-auto">*/}
                  {/*  <div className="btn btn-sm btn-icon btn-dark">*/}
                  {/*    <MailSVG/>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </li>

              {/*<li className="list-group-item">*/}
              {/*  <div className="row align-items-center gx-6">*/}
              {/*    <div className="col">*/}
              {/*      <h5>手机</h5>*/}
              {/*      <p>{}</p>*/}
              {/*    </div>*/}

              {/*    <div className="col-auto">*/}
              {/*      <div className="btn btn-sm btn-icon btn-dark">*/}
              {/*        <PhoneCallSVG/>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</li>*/}
            </ul>
            {/* List  */}

            <hr className="hr-bold modal-gx-n my-0"/>

            {/* List */}
            {/*<ul className="list-group list-group-flush">*/}
            {/*  <li className="list-group-item">*/}
            {/*    <div className="row align-items-center gx-6">*/}
            {/*      <div className="col">*/}
            {/*        <h5>接待状态</h5>*/}
            {/*        <p></p>*/}
            {/*      </div>*/}

            {/*      <div className="col-auto">*/}
            {/*        <div className="form-check form-switch">*/}
            {/*          <input className="form-check-input" type="checkbox" id="online-status" checked/>*/}
            {/*          <label className="form-check-label" htmlFor="online-status"></label>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/* List */}

            {/*<hr className="hr-bold modal-gx-n my-0"/>*/}

            {/* List */}
            <ul className="list-group list-group-flush">
              <li className="list-group-item text-center">
                <a href="#" onClick={downloadQrImage}>点击下载客服二维码</a>
              </li>
            </ul>
            {/* List */}
          </div>
          {/* Modal body */}

        </div>
      </div>
    </div>
  );
}


export default ProfileServicerModal;
