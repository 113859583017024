import React from 'react';

import {ReactComponent as CardHeaderSVG} from '../../assets/img/core/card-header.svg';
import {ReactComponent as GlobeSVG} from '../../assets/img/icons/globe.svg';
import {ReactComponent as MailSVG} from '../../assets/img/icons/mail.svg';
import {ReactComponent as PhoneCallSVG} from '../../assets/img/icons/phone-call.svg';
import {ReactComponent as MenuSVG} from '../../assets/img/icons/menu.svg';
import avatarKH from '../../assets/img/avatars/14.jpg';
import {Customer} from "../../models/types";

type UserInfoProps = {
  userInfo: Customer
}

const ProfileUserModal = (props: UserInfoProps) => {

  const { userInfo } = props

  return (
    <div className="modal fade" id="modal-profile-user" tabIndex={-1} aria-labelledby="modal-profile-user" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content">

          {/* Modal body */}
          <div className="modal-body py-0">
            {/* Header */}
            <div className="profile modal-gx-n">
              <div className="profile-img text-primary rounded-top-xl">
                <CardHeaderSVG/>

                <div className="position-absolute top-0 start-0 py-6 px-5">
                  <button type="button" className="btn-close btn-close-white btn-close-arrow opacity-100"
                          data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>

              <div className="profile-body">
                <div className="avatar avatar-xl">
                  {
                    (userInfo.avatar === "")
                      ?
                      <span className="avatar-text">客户</span>
                      :
                      <img className="avatar-img" src={userInfo.avatar} alt={userInfo.real_name !== "" ? userInfo.real_name : userInfo.name}/>
                  }
                </div>

                <h4 className="mb-1">{userInfo.real_name !== "" ? userInfo.real_name : userInfo.name}</h4>
                {/*<p>在线状态：</p>*/}
              </div>
            </div>
            {/* Header */}

            <hr className="hr-bold modal-gx-n my-0"/>

            {/* List */}
            <ul className="list-group list-group-flush">
              {/*<li className="list-group-item">*/}
              {/*  <div className="row align-items-center gx-6">*/}
              {/*    <div className="col">*/}
              {/*      <h5>位置</h5>*/}
              {/*      <p>中国 - 上海</p>*/}
              {/*    </div>*/}

              {/*    <div className="col-auto">*/}
              {/*      <div className="btn btn-sm btn-icon btn-dark">*/}
              {/*        <GlobeSVG/>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</li>*/}

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>邮箱</h5>
                    <p>{userInfo.email}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <MailSVG/>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>手机</h5>
                    <p>{userInfo.mobile}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <PhoneCallSVG/>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>地址</h5>
                    <p>{userInfo.address}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <GlobeSVG/>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>备注</h5>
                    <p>{userInfo.note}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <MenuSVG/>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            {/* List  */}

            <hr className="hr-bold modal-gx-n my-0"/>

            {/* List */}
            {/*<ul className="list-group list-group-flush">*/}
            {/*  <li className="list-group-item">*/}
            {/*    <div className="row align-items-center gx-6">*/}
            {/*      <div className="col">*/}
            {/*        <h5>通知状态</h5>*/}
            {/*        <p></p>*/}
            {/*      </div>*/}

            {/*      <div className="col-auto">*/}
            {/*        <div className="form-check form-switch">*/}
            {/*          <input className="form-check-input" type="checkbox" id="notice-status" checked/>*/}
            {/*          <label className="form-check-label" htmlFor="notice-status"></label>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/* List */}

            {/*<hr className="hr-bold modal-gx-n my-0"/>*/}

            {/* List */}
            <ul className="list-group list-group-flush">
              <li className="list-group-item text-center">
                {/*<span*/}
                {/*  className="text-danger"*/}
                {/*  style={{cursor: "pointer"}}*/}
                {/*  onClick={upgradeService}*/}
                {/*>添加客户</span>*/}
              </li>
            </ul>
            {/* List */}
          </div>
          {/* Modal body */}

        </div>
      </div>
    </div>
  );
}


export default ProfileUserModal;
