import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from "react-router-dom";
import CustomerService from '../services/customer';
// import {Customer, Servicer} from '../models/types';

const CustomerInfo = () => {

  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');
  type typeFormData = {
    code: string
    real_name: string
    mobile: string
    email: string
  }

  const defaultFormData = {
    code: '',
    real_name: '',
    mobile: '',
    email: '',
  }

  const [formData, setFormData] = useState<typeFormData>(defaultFormData);

  useEffect(() => {
    if (code === undefined || code === null || code === "") {
      return
    }
    CustomerService.get_list({code: code}).then((e) => {
      if (e.data.count === 0) {
        return
      }
      // setToken(e.data.data[0].name);
      setFormData({
        ...formData,
        ['code']: code,
        ['real_name']: e.data.data[0].real_name,
        ['mobile']: e.data.data[0].mobile,
        ['email']: e.data.data[0].email,
      });
      console.log(formData);
    });
  }, [code]);

  const handleChange = (e: any) => {
    const {name, value} = e.target;
    const data = {
      ...formData,
      [name]: value,
    }
    setFormData(data);
    console.log(data);
  };

  const setCustomerInfo = () => {
    if (formData.code === '') {
      alert('参数缺失');
      return;
    }
    if (formData.real_name === '') {
      alert('姓名必填');
      return;
    }
    if (formData.mobile === '') {
      alert('手机必填');
      return;
    }
    CustomerService.set_info(formData).then((e) => {
      console.log('Success:', e.data);
      alert('保存成功');
    }).catch((error) => {
      console.error('Error:', error);
      alert('保存失败');
      // alert(error.response.data.cause);
    });
  }

  return (
    <div className="container">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">

        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              <div className="row g-6">
                <div className="col-12">
                  <div className="text-center">
                    <h3 className="fw-bold mb-2">档案信息</h3>
                    <p>信息完善</p>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="real_name"
                      name="real_name"
                      placeholder="姓名"
                      value={formData.real_name}
                      onChange={handleChange}
                    />
                    <label htmlFor="real_name">姓名</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="mobile"
                      name="mobile"
                      placeholder="手机"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                    <label htmlFor="mobile">手机</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="邮箱"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <label htmlFor="email">邮箱</label>
                  </div>
                </div>

                <div className="col-12">
                  <button
                    className="btn btn-block btn-lg btn-primary w-100" type="submit"
                    onClick={() => {
                      setCustomerInfo()
                    }}
                  >提交
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-8">
            <p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default CustomerInfo;
