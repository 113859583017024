import React, {useEffect, useState} from 'react';

import {ReactComponent as ChevronRightSVG} from '../../assets/img/icons/chevron-right.svg';
import KnowledgeService from '../../services/knowledge';
import {Knowledge} from "../../models/types";

type DataProps = {
  setContent: Function
}

const DataOffCanvas = (props: DataProps) => {

  const { setContent } = props

  const [knowledgeList, setKnowledgeList] = useState<Knowledge[]>([]);

  const [keyword, setKeyword] = useState<string>('你好');
  const [reply, setReply] = useState<string>('');


  const handleSearch = (e: any) => {
    console.log(e.target.value);
    setKeyword(e.target.value);
  };

  const handleChange = (e: any) => {
    console.log(e.target.value);
    setReply(e.target.value);
  };

  useEffect(() => {

    const reqGetKnowledgeList = {
      content: keyword
    };

    KnowledgeService.get_list(reqGetKnowledgeList).then((e) => {
      console.log('Success:', e.data);
      setKnowledgeList(e.data);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }, [keyword]);

  return (
    <div className="offcanvas offcanvas-end offcanvas-aside" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvas-data">
      {/*OffCanvas Header*/}
      <div className="offcanvas-header py-4 py-lg-7 border-bottom ">
        <a className="icon icon-lg text-muted" href="#" data-bs-dismiss="offcanvas">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
        </a>

        <div className="visibility-xl-invisible overflow-hidden text-center">
          <h5 className="text-truncate">快捷回复</h5>
          <p className="text-truncate">内容来源于知识库</p>
        </div>

        <a className="icon icon-lg text-muted" data-bs-toggle="collapse" href="#search-data" role="button" aria-expanded="false" aria-controls="search-data" onClick={(event) => {event.preventDefault();}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
        </a>
      </div>

      {/*OffCanvas Body*/}
      <div className="offcanvas-body hide-scrollbar py-0">

        {/*Search*/}
        <div className="collapse" id="search-data">
          <div className="border-bottom py-6">

            <form action="#">
              <div className="input-group">
                <div className="input-group-text" id="search-user">
                  <div className="icon icon-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                  </div>
                </div>

                <input
                  type="text"
                  name="content"
                  className="form-control form-control-lg ps-0"
                  placeholder="知识库搜索"
                  autoComplete="off"
                  aria-label="知识库搜索"
                  aria-describedby="search-user"
                  onChange={handleSearch}
                />
              </div>
            </form>

          </div>
        </div>

        {/* 数据列表 */}
        <ul className="list-group list-group-flush">
          {knowledgeList.map((item, index) => (
            <>
              <li className="list-group-item" key={index}>
                <small className="text-uppercase text-muted">{item.group}</small>
              </li>
              {item.items.map((item_qa, index_qa) => (
                <li className="list-group-item" key={index+"-"+index_qa}>
                  <div className="row align-items-center gx-5">
                    <div className="col-auto">
                      <div className="avatar ">
                        <img className="avatar-img" src="" alt=""/>
                      </div>
                    </div>
                    <div className="col">
                      <h5>{item_qa.question}</h5>
                      <p>{item_qa.reply}</p>
                    </div>
                    <div className="col-auto">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="reply"
                          value={item_qa.reply}
                          id={"id-qa-"+index+"-"+index_qa}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor={"id-qa-"+index+"-"+index_qa}></label>
                      </div>
                    </div>
                  </div>
                  <label className="stretched-label" htmlFor={"id-qa-"+index+"-"+index_qa}></label>
                </li>
              ))}
            </>
          ))}
        </ul>
      </div>

      {/*OffCanvas Footer*/}
      <div className="offcanvas-footer border-top py-4 py-lg-7">
        <a href="#" className="btn btn-lg btn-primary w-100 d-flex align-items-center" onClick={() => setContent(reply)}>
          快捷回复
          <span className="icon ms-auto">
            <ChevronRightSVG/>
          </span>
        </a>
      </div>
    </div>
  );
}


export default DataOffCanvas;
